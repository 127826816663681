<template>
  <div class="xieyimain">
    <div v-html="data"></div>
  </div>
</template>

<script>
import apiUrl from '@/api/main'
export default {
  components: {},
  data() {
      return {
        data:''
      };
  },
  computed: {},
  watch: {},
  methods: {
    getdata(){
      console.log('zhuce')
      let data = {
        title: '隐私协议',
      }
      apiUrl.lsXieyiinfo(data).then((res) => {
        this.data = res.data.content;
      })
    }
  },
  created() {},
  mounted() {
    this.getdata();
    // 跳转回顶部
    document.documentElement.scrollTop = 0;
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  }
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.xieyimain{
  padding: 10px 40px;
}

</style>